import React from 'react';


const FeatureListing = ({ item }) => {
	return (
		<section className="container">
			UNFINISHED MODULE FeatureListing
		</section>
	);
}

export default FeatureListing;
